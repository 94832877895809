import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../ALL Css/ALL Dashboard CSS/ChapterDetails.css';
import AdminHome from '../AdminHome';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ChapterDetails = () => {
  const { suCode, boardCode, classCode, subjectCode } = useParams();
  const userData = JSON.parse(sessionStorage.getItem('user'));
  const userEmail = userData?.EmailID;
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedChapters, setExpandedChapters] = useState({});
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editChapterName, setEditChapterName] = useState('');
  const [editChapterCode, setEditChapterCode] = useState('');
  const [mediaUrl, setMediaUrl] = useState('');
  const [pptUrl, setPptUrl] = useState('');
  const [selectedTopicCode, setSelectedTopicCode] = useState(null);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [selectedMedia, setSelectedMedia] = useState('');
  const [isPptFullscreen, setIsPptFullscreen] = useState(false);
  const [mediaNotAvailable, setMediaNotAvailable] = useState(false);

  useEffect(() => {
    const fetchChapters = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_BASE_URL}/api/chapter-details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ suCode, boardCode, classCode, subjectCode }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch chapter details');
        }
        const data = await response.json();
        const uniqueChapters = data.filter((chapter, index, self) =>
          index === self.findIndex((t) => t.ChapterName === chapter.ChapterName)
        );
        const initializedChapters = uniqueChapters.map((chapter) => ({
          ...chapter,
          Topics: chapter.Topics || [],
        }));

        setChapters(initializedChapters);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (suCode && boardCode && classCode && subjectCode) {
      fetchChapters();
    } else {
      setError('One or more parameters are missing.');
      setLoading(false);
    }
  }, [suCode, boardCode, classCode, subjectCode]);


  const toggleChapter = async (chapterCode) => {
    setExpandedChapters((prev) => ({
      ...prev,
      [chapterCode]: !prev[chapterCode],
    }));

    if (!expandedChapters[chapterCode]) {
      try {
        const response = await fetch(`${API_BASE_URL}/api/topic-details`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ suCode, boardCode, classCode, subjectCode, chapterCode }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch topic details');
        }

        const topicsData = await response.json();
        setChapters((prevChapters) =>
          prevChapters.map((chapter) =>
            chapter.ChapterCode === chapterCode
              ? { ...chapter, Topics: topicsData || [] }
              : chapter
          )
        );
      } catch (error) {
        setError(error.message);
      }
    }
  };

  const handleUpdateChapter = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/update-chapter`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ chapterCode: editChapterCode, chapterName: editChapterName }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update chapter');
      }
      setChapters((prevChapters) =>
        prevChapters.map((chapter) =>
          chapter.ChapterCode === editChapterCode
            ? { ...chapter, ChapterName: editChapterName }
            : chapter
        )
      );
      setIsEditModalOpen(false);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleEditClick = (chapter) => {
    setEditChapterName(chapter.ChapterName);
    setEditChapterCode(chapter.ChapterCode);
    setIsEditModalOpen(true);
  };


  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setEditChapterName('');
    setEditChapterCode('');
  };
  

  const handleEditTopic = (topic) => {
    setSelectedTopic(topic);
  };

  // Function to update the topic
  const handleUpdateTopic = async () => {
    if (!selectedTopic?.TopicName) {
      setError('Topic name is required');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/api/update-topic`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          boardCode,
        classCode,
        subjectCode,
        chapterCode: selectedTopic.ChapterCode, 
        topicCode: selectedTopic.TopicCode,
        topicName: selectedTopic.TopicName,
        pptUrl: selectedTopic.PPTUrl || '',
        videosUrl: selectedTopic.VideosUrl || '',
        worksheetUrl: selectedTopic.WorksheetUrl || '',
        activityVideo: selectedTopic.ActivityVideo || '',
        activityItemList: selectedTopic.ActivityItemList || '',
        simulationVideo: selectedTopic.SimulationVideo || '',
        updatedBy: userEmail
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update topic');
      }

      setChapters((prevChapters) =>
        prevChapters.map((chapter) => {
          if (Array.isArray(chapter.Topics)) {
            return {
              ...chapter,
              Topics: chapter.Topics.map((topic) =>
                topic.TopicCode === selectedTopic.TopicCode
                  ? { ...topic, TopicName: selectedTopic.TopicName }
                  : topic
              ),
            };
          }
          return chapter;
        })
      );
      setSelectedTopic(null);
    } catch (error) {
      setError(error.message);
    }
  };


  const fetchMedia = async ({ topicCode, chapterCode }) => {
    try {
      const response = await fetch(`${API_BASE_URL}/api/topic-video`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ topicCode, suCode, boardCode, classCode, subjectCode, chapterCode }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch media');
      }

      const { videoUrl, pptUrl } = await response.json();
      setMediaUrl(videoUrl);
      setPptUrl(pptUrl);
      setSelectedTopicCode(topicCode);
      setMediaNotAvailable(!videoUrl && !pptUrl);
    } catch (error) {
      setError(error.message);
      setMediaUrl('');
      setPptUrl('');
      setSelectedTopicCode(null);
      setMediaNotAvailable(true);
    }
  };

  const handleTopicClick = (topicCode, chapterCode) => {
    if (selectedTopicCode === topicCode) {
      setSelectedTopicCode(null);
      setPptUrl('');
      setMediaUrl('');
      setSelectedMedia('');
      setMediaNotAvailable(false);
    } else {
      fetchMedia({ topicCode, chapterCode });
      setSelectedTopicCode(topicCode);
    }
  };


  const handleMediaSelection = (mediaType) => {
    if (selectedMedia === mediaType) {
      setSelectedMedia('');
    } else {
      setSelectedMedia(mediaType);
      if (mediaType === 'PPT' && pptUrl) {
        window.open(pptUrl, '_blank');
      }
    }
  };



  const handleFullscreen = () => {
    const pptElement = document.getElementById('ppt-viewer');
    if (pptElement) {
      if (isPptFullscreen) {
        document.exitFullscreen();
      } else {
        pptElement.requestFullscreen();
      }
      setIsPptFullscreen(!isPptFullscreen);
    }
  };


  return (
    <AdminHome>
      <div className="chapter-details">
        {loading && <p>Loading...</p>}
        {error && <p>{error}</p>}

        <ul>
          {chapters.map((chapter) => (
            <li key={chapter.ChapterCode}>
              <div className='Chapter-list' onClick={() => toggleChapter(chapter.ChapterCode)}>
                <span className="chapter-slno" style={{ marginRight: '10px', color: 'white' }}>
                  {chapter.ChapterSlNo}.
                </span>
                <span>
                  <span style={{ marginRight: '5px' }}>
                    {expandedChapters[chapter.ChapterCode] ? '▼' : '▲'}
                  </span>
                  {chapter.ChapterName}
                  <span className='Edit-icon' style={{ color: 'blue', marginLeft: '20px' }}>
                    <i className="bi bi-pencil" onClick={() => handleEditClick(chapter)}></i>
                  </span>
                </span>
                <span style={{ float: 'right' }}>
                  {chapter.Topics && chapter.Topics > 0 && `${chapter.Topics} topics`}
                  {expandedChapters[chapter.ChapterCode]
                    ? `${chapter.Topics.length} topics`
                    : chapter.Topics && chapter.Topics.length > 0 && `${chapter.Topics.length} topics`}
                </span>
              </div>
              {expandedChapters[chapter.ChapterCode] && (
                <ul className="expanded-content">
                  {chapter.Topics && chapter.Topics.length > 0 ? (
                    chapter.Topics.map((topic) => {
                      const hasPpt = Boolean(topic.PptUrl);
                      const hasVideo = Boolean(topic.VideoUrl);
                      const hasAssignment = Boolean(topic.Assignment);

                      return (
                        <li key={topic.TopicCode}>
                          <div
                            onClick={() => handleTopicClick(topic.TopicCode, chapter.ChapterCode)}
                            className={selectedTopicCode === topic.TopicCode ? 'expanded-topic' : ''}
                            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                          >
                            <span className="topic-slno" style={{ marginRight: '10px', color: 'black' }}>
                              {chapter.ChapterSlNo}.{topic.TopicSLNo}.
                            </span>
                            <span>{selectedTopicCode === topic.TopicCode ? '[-]' : '[+]'} {topic.TopicName}
                              <span className='edit-icon-topic' style={{ color: 'blue', marginLeft: '10px' }}>
                                <i className="bi bi-pencil" onClick={() => handleEditTopic(topic)}></i>
                              </span>
                            </span>
                            <div className="topic-icons">
                              <span
                                className="icon1"
                                style={{ color: hasPpt ? 'deepblue' : 'lightgray' }}
                              >
                                <i className="bi bi-file-earmark-richtext"></i>
                              </span>
                              <span
                                className="icon2"
                                style={{ color: hasVideo ? 'deepblue' : 'lightgray' }}
                              >
                                <i className="bi bi-play-circle"></i>
                              </span>
                              <span
                                className="icon3"
                                style={{ color: hasAssignment ? 'deepblue' : 'lightgray' }}
                              >
                                <i className="bi bi-pencil-square"></i>
                              </span>
                              <span className='icon4'>
                                <i className="bi bi-film"></i>
                              </span>
                              <span className='icon5'>
                                <i className="bi bi-list-check"></i>
                              </span>
                              <span className='icon6'>
                                <i className="bi bi-graph-up"></i>
                              </span>
                            </div>
                          </div>

                          {selectedTopicCode === topic.TopicCode && (
                            <div style={{ padding: '10px', borderRadius: '5px' }}>
                              <div className='Three-button'>
                                <div
                                  className='First-button'
                                  data-media="PPT"
                                  onClick={() => handleMediaSelection('PPT')}
                                  style={{ color: hasPpt ? 'deepblue' : 'lightgray' }}
                                >
                                  <i className="bi bi-file-earmark-richtext"></i>
                                  PPT
                                </div>
                                <div
                                  className='Second-button'
                                  data-media="VIDEO"
                                  onClick={() => handleMediaSelection('VIDEO')}
                                  style={{ color: hasVideo ? 'deepblue' : 'lightgray' }}
                                >
                                  <i className="bi bi-play-circle"></i>
                                  VIDEO
                                </div>
                                <div
                                  className='Third-button'
                                  data-media="A"
                                  onClick={() => handleMediaSelection('A')}
                                  style={{ color: hasAssignment ? 'deepblue' : 'lightgray' }}
                                >
                                  <i className="bi bi-pencil-square"></i>
                                  Assignment
                                </div>
                                <div
                                  className='Fourth-button'
                                  data-media="Activity Video"
                                >
                                  <i className="bi bi-film"></i>
                                  Activity Video
                                </div>
                                <div
                                  className='Fifth-button'
                                  data-media="Activity Item List"
                                >
                                  <i className="bi bi-list-check"></i>
                                  Activity Item List
                                </div>
                                <div
                                  className='Sixth-button'
                                  data-media="Simulation"
                                >
                                  <i className="bi bi-graph-up"></i>
                                  Simulation
                                </div>
                              </div>

                              {/* Conditional Rendering for Selected Media */}
                              {selectedMedia === 'VIDEO' && mediaUrl ? (
                                <div className="media-player">
                                  <iframe
                                    width="100%"
                                    height="600"
                                    src={mediaUrl}
                                    title="Media Player"
                                    frameBorder="0"
                                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                  <button onClick={handleFullscreen}></button>
                                </div>
                              ) : mediaNotAvailable && <p>No media available for this topic.</p>}

                            </div>
                          )}
                        </li>
                      );
                    })
                  ) : (
                    <p>No topics available for this chapter.</p>
                  )}
                </ul>
              )}
            </li>
          ))}
        </ul>

        {/* Edit Modal */}
        {isEditModalOpen && (
          <div className="edit-modal">
            <h3>Edit Chapter Name</h3>
            <input
              type="text"
              value={editChapterName}
              onChange={(e) => setEditChapterName(e.target.value)}
            />
            <div className="modal-footer">
              <button type="button" class="close" onClick={() => closeEditModal(false)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <button type="submit" className="Submit-button" onClick={handleUpdateChapter}>Update
              </button>
            </div>
          </div>
        )}

        {selectedTopic && (
          <div className="Topic-edit-details">
          <div className="Topic-edit-modal">
            <h4 className='Topic-edit-modal-heading'>Edit Topic</h4>

            <div className="row">
              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Topic Name:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.TopicName}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, TopicName: e.target.value })}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">PPT URL:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.PPTUrl}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, PPTUrl: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.PPTUrl} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.PPTUrl}</a></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Videos URL:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.VideosUrl}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, VideosUrl: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.VideosUrl} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.VideosUrl}</a></p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Worksheet URL:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.WorksheetUrl}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, WorksheetUrl: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.WorksheetUrl} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.WorksheetUrl}</a></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Activity Video:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.ActivityVideo}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, ActivityVideo: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.ActivityVideo} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.ActivityVideo}</a></p>
                </div>
              </div>

              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Activity Item List:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.ActivityItemList}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, ActivityItemList: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.ActivityItemList} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.ActivityItemList}</a></p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="Topic-edit-modal-form-group">
                  <label className="Topic-edit-modal-label">Simulation Video:</label>
                  <input
                    type="text"
                    className="Topic-edit-modal-input"
                    value={selectedTopic.SimulationVideo}
                    onChange={(e) => setSelectedTopic({ ...selectedTopic, SimulationVideo: e.target.value })}
                  />
                  <p className="Topic-edit-modal-text">Current: <a href={selectedTopic.SimulationVideo} target="_blank" rel="noopener noreferrer" className="Topic-edit-modal-link">{selectedTopic.SimulationVideo}</a></p>
                </div>
              </div>
            </div>

            <div className="Topic-edit-modal-footer">
              <button type="button" class="close" onClick={() => setSelectedTopic(null)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <button type="submit" className="Submit-button" onClick={handleUpdateTopic}>Update
              </button>
            </div>
          </div>
          </div>
        )}


      </div>
    </AdminHome>

  );
};

export default ChapterDetails;
