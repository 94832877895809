import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import RegistrationForm from '../Components/Registration & Login/RegistrationForm';
import LoginSelect from '../Components/Registration & Login/LoginSelect';
import FunScholarAdmin from '../Components/Registration & Login/LoginTypes/FunScholarAdmin';
import Home from '../Components/Dashboard/Home';
import ChapterDetails from '../Components/AdminPart/AdminDetails/ChapterDetails';
import AdminHome from '../Components/AdminPart/AdminHome';
import ClassDetails from '../Components/AdminPart/AdminDetails/ClassDetails';
import SubjectDetails from '../Components/AdminPart/AdminDetails/SubjectDetails';
import BoardDetails from '../Components/AdminPart/AdminDetails/BoardDetails';
import StudyMaterials from '../Components/AdminPart/AdminDetails/StudyMaterials';
import Panels from '../Components/AdminPart/AdminDetails/Panels';
import Dashboard from '../Components/AdminPart/AdminDetails/Dashboard';
import MyProfile from '../Components/AdminPart/AdminDetails/MyProfile';



const RoutesConfig = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/registrationform" element={<RegistrationForm />} />
        <Route path="/home" element={<Home />} />
        <Route path="/loginselect" element={<LoginSelect />} />
        <Route path="/funscholaradmin" element={<FunScholarAdmin />} />
        <Route path="/boarddetails" element={<BoardDetails />} />
        <Route path="/classdetails/:suCode/:boardCode" element={<ClassDetails />} />
        <Route path="/subjects/:suCode/:boardCode/:classCode" element={<SubjectDetails />} />
        <Route path="/chapterdetails/:suCode/:boardCode/:classCode/:subjectCode" element={<ChapterDetails />} />
        <Route path="/adminhome" element={<AdminHome />} />
        <Route path="/studymaterials" element={<StudyMaterials />} />
        <Route path="/panels" element={<Panels />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<MyProfile />} />
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesConfig;
